<template>
  <v-dialog v-model="showModalDeleteRating" width="480px" persistent lazy scrollable>
    <v-card>
      <v-toolbar color="error" card dark>
        <v-toolbar-title>Desactivar Rating</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="replaceShowModalDeleteRating({ status: false })">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <center style="padding:20px 0;">
        <p>¿Está seguro de desactivar el comentario?</p>
        <template v-if="rating">
         <strong>comentario: </strong> {{ rating.comentario }}          
        </template>
      </center>
      <v-divider />
      <div class="my-3 text-xs-center">
        <v-btn
          :disabled="processingDelete"
          :loading="processingDelete"
          color="error"
          @click="launchDelete"
        >
          Eliminar
        </v-btn>
        <v-btn
          :disabled="processingDelete"
          @click="replaceShowModalDeleteRating({ status: false })"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      processingDelete: false
    };
  },

  computed: {
    ...mapState({
      showModalDeleteRating: state => state.ratings.showModalDeleteRating,
      rating: state => state.ratings.currentRating
    })
  },

  watch: {
    showModalDeleteRating(newValue, oldValue) {
      if (!newValue) {
        this.replaceCurrentRating({ rating: null });
        return false;
      }
    }
  },

  methods: {
    ...mapActions({
      replaceShowModalDeleteRating: "ratings/replaceShowModalDeleteRating",
      replaceCurrentRating: "ratings/replaceCurrentRating",
      deleteRating: "ratings/deleteRating",
    }),

    launchDelete() {
      this.processingDelete = true;
      this.deleteRating({ ratingId: this.rating.id })
        .then(response => {
          this.processingDelete = false;
          this.replaceShowModalDeleteRating({ status: false });
					this.$emit('change')
        })
        .catch(() => {
          this.processingDelete = false;
        });
    }
  }
};
</script>
